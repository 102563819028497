import { Link } from "gatsby"
import React from "react"

import about from "../assets/About.png"
import sun from "../assets/Sun.png"

const About = () => {
  return (
    <div className="relative bg-white">
      <div className="flex flex-col max-w-screen-2xl px-4 pt-12 mx-auto">
        <h2 className="px-4 font-heading text-3xl mx-auto text-ec-earth text-center">
          About Elemental Connection
        </h2>
        <p className="max-w-3xl text-center font-body text-xl text-ec-sea leading-8 mx-auto py-12">
          Born from the passionate soul of Deanne Leckie, Elemental Connection
          was created in 2013 to provide a community which fosters zen and
          safety away from the stress of everyday life. Either guided to seek
          your own spirituality or a want to get lost in crystals and colour, we
          seek to hollistically guide your souls journey.
        </p>
        <Link
          to="/contact"
          className="font-heading text-xl text-white bg-ec-earth border-2 border-ec-earth mx-auto text-center rounded-full py-2 px-8 transition ease-in-out hover:text-ec-earth hover:bg-transparent "
        >
          Contact Us here
        </Link>
        <div className="">
          <img
            src={about}
            alt=""
            className="w-[700px] mx-auto mt-12"
          />
          <img src={sun} alt="" className="absolute -bottom-3 sm:-bottom-[19px] left-1/2 text-center w-[200px] -ml-[100px] sm:-ml-[150px]  sm:w-[300px]" />
        </div>
      </div>
    </div>
  )
}

export default About
