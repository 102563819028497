import React from "react"
import { Link } from "gatsby"
import header from "../assets/Header Image Placeholder.jpg"
import feather from "../assets/Feather line.png"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { StaticImage } from "gatsby-plugin-image"
import LineBreak from "../assets/icons/lineBreak"

const Hero = () => {
  return (
    <div className="relative">
      <div className="absolute inset-x-0 bottom-0  bg-gray-100 "></div>
      <div className="mx-auto">
        <div className="relative sm:overflow-hidden">
          <div className="absolute inset-0">
            <StaticImage
              className="h-full w-full object-cover"
              src="../assets/Header Image Placeholder.jpg"
              alt="Crystals"
            />
            <div className="absolute inset-0 "></div>
          </div>
          <div className="flex flex-col sm:grid sm:grid-cols-3 max-w-screen-2xl mx-auto px-4 pb-4">
            <div className="relative mt-24 md:mt-48 px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <StaticImage
                src="../assets/Elemental Connection Text Logo.png"
                alt="Elemental Connection Logo"
                loading="eager"
              />
              <div className="mt-6">
                <LineBreak className="" />
              </div>
              <div className="py-2">
                <h1 className="text-2xl font-heading text-ec-earth ">
                  Crystals & Holistic Treatments
                </h1>
              </div>

              <p className="mt-6 max-w-lg mx-auto text-left font-body text-xl text-ec-sea sm:max-w-3xl pb-2">
                Like a phoenix from the ashes, We are back and <strong>more magical than ever!!!</strong>
              </p>
              <p className=" max-w-lg mx-auto text-left font-body text-xl text-ec-sea sm:max-w-3xl">
                From crystals to books, cards to jewellery, candles to essential oils, we have just what you need to add a spark of magic to your life's journey.
              </p>
              <div className="mt-10 max-w-sm sm:mx-auto sm:max-w-none sm:flex">
                <div className="">
                  <a
                    href="/catalogue"
                    className="flex items-center justify-center px-4 py-3 border-2 border-ec-earth rounded-full text-white bg-ec-earth hover:bg-transparent hover:text-ec-earth transition ease-in-out text-xl font-heading  sm:px-8"
                  >
                    {" "}
                    Shop our Collection{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="relative col-span-1 flex justify-center sm:mt-32">
              <img src={feather} className="h-40 sm:h-72" alt="white feather"/>
            </div>
            <div className="col-span-1"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
