import React from "react"
import SEO from "../content/Seo"
import About from "../content/About"
import Categories from "../content/Categories"
import Featured from "../content/Featured"
import Hero from "../content/Hero"

import Layout from "../content/Layout"
import Contact from "../content/Contact"
import HeroNewsletter from "../content/HeroNewsletter"

const Home = () => {
  return (
    <Layout>
      <SEO
        title="Crystals & Holistic Treatments"
        slug="/"
        image="../assets/Header Image Placeholder.jpg"
      />
      <main className="pt-12">
        <Hero />
        <HeroNewsletter />
        <Categories />
        <About />
        <div className="bg-ec-air">
          <h2 className="px-4 font-heading text-3xl mx-auto text-ec-earth text-center py-12">
            Brand New Products
          </h2>
          <Featured />
        </div>
        <Contact />
      </main>
    </Layout>
  )
}

export default Home
