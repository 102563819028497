import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import slugify from "@sindresorhus/slugify"

import { ProductCard } from "../components/product-card"

const query = graphql`
  {
    shopifyCollection(handle: { eq: "frontpage" }) {
      products {
        ...ProductCard
      }
    }
  }
`

const Featured = () => {
  const data = useStaticQuery(query)
  const products = data?.shopifyCollection?.products
  return (
    <section className="max-w-screen-2xl mx-auto">
      <div className="md:grid md:grid-cols-2 xl:grid-cols-4 mx-auto justify-center relative text-center p-4">
        {products.map((product, index) => (
          <ProductCard product={product} key={index} />
        ))}
      </div>
    </section>
  )
}

export default Featured
