import React from "react"

import { useForm } from "@formspree/react"

import { CheckCircleIcon, XIcon } from "@heroicons/react/solid"

const HeroNewsletter = () => {
  const [state, handleSubmit] = useForm("xdojoaqw")

  if (state.succeeded) {
    return (
      <div className="bg-ec-sky">
        <div className=" lg:flex py-12 px-4 lg:items-center lg:justify-between  max-w-7xl mx-auto">
          <div className="text-center md:text-left md:pr-32 pr-32">
            <h3 className="text-3xl pb-2 text-white font-heading">
              Stay Connected with Elemental Connection
            </h3>
            <p className="text-lg text-white font-body">
              Be the first to know about our new trends, get exclusive access to
              great deals and live streams, and be the first to see new arrivals
              in store. Sign up now!
            </p>
          </div>
          <div className="mx-auto text-center">
            <form
              onSubmit={handleSubmit}
              className="mt-4 sm:flex sm:max-w-md lg:mt-0"
            >
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email"
                type="email"
                name="email"
                invalidtext="Invalid Email"
                autoComplete="email"
                required
                className="py-2 my-4 md:my-0 appearance-none max-w-96 lg:w-96 min-w-0 w-full bg-ec-sky border-2 rounded-full border-white py-2 px-4 text-base text-white text-center placeholder-white focus:outline-none focus:border-white focus:placeholder-white sm:max-w-xs"
                placeholder="Enter your email"
              />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                {state.submitting ? (
                  <div>
                    <button
                      type="submit"
                      disable={state.submitting ? true : undefined}
                      className="w-1/2 mx-auto md:w-full rounded-full py-2 px-8 flex items-center justify-center text-lg font-heading font-bold text-ec-sky bg-white hover:bg-ec-sky hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition ease-in-out border-2 border-ec-sky hover:border-white"
                    >
                      <svg
                        className="fill-ec-sky hover:fill-white animate-spin mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" />
                      </svg>
                      Submitting...
                    </button>
                  </div>
                ) : (
                  <div>
                    {" "}
                    <button
                      type="submit"
                      disable={state.submitting ? true : undefined}
                      className="w-1/2 mx-auto md:w-full rounded-full py-2 px-8 flex items-center justify-center text-lg font-heading font-bold text-ec-sky bg-white hover:bg-ec-sky hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition ease-in-out border-2 border-ec-sky hover:border-white"
                    >
                      Subscribe
                    </button>
                  </div>
                )}
              </div>
            </form>
            <div className=" bg-ec-sea p-4 mt-4 rounded-full">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-white">
                    Your submission was successful!
                  </p>
                </div>
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      onClick={() => window.location.reload(false)}
                      type="button"
                      className="inline-flex p-1.5 text-white rounded-full hover:bg-ec-sky/20 focus:outline-none"
                    >
                      <span className="sr-only">Dismiss</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="bg-ec-sky">
      <div className=" lg:flex py-8 px-4 lg:items-center lg:justify-between  max-w-7xl mx-auto">
        <div className="text-center md:text-left md:pr-32">
          <h3 className="text-3xl pb-2 text-white font-heading">
            Stay Connected with Elemental Connection
          </h3>
          <p className="text-lg text-white font-body">
            Be the first to know about our new trends, get exclusive access to
            great deals and live streams, and be the first to see new arrivals
            in store. Sign up now!
          </p>
        </div>
        <div className="mx-auto text-center">
          <form
            onSubmit={handleSubmit}
            className="mt-4 sm:flex sm:max-w-md lg:mt-0"
          >
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email"
              type="email"
              name="email"
              invalidtext="Invalid Email"
              autoComplete="email"
              required
              className="py-2 my-4 md:my-0 appearance-none max-w-96 lg:w-96 min-w-0 w-full bg-ec-sky border-2 rounded-full border-white py-2 px-4 text-base text-white text-center placeholder-white focus:outline-none focus:border-white focus:ring-0 focus:placeholder-white sm:max-w-xs"
              placeholder="Enter your email"
            />
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              {state.submitting ? (
                <div>
                  <button
                    type="submit"
                    disable={state.submitting ? true : undefined}
                    className="w-1/2 mx-auto md:w-full rounded-full py-2 px-8 flex items-center justify-center text-lg font-heading font-bold text-ec-sky bg-white hover:bg-ec-sky hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition ease-in-out border-2 border-ec-sky hover:border-white"
                  >
                    <svg
                      className="fill-ec-sky hover:fill-white animate-spin mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" />
                    </svg>
                    Submitting...
                  </button>
                </div>
              ) : (
                <div>
                  {" "}
                  <button
                    type="submit"
                    disable={state.submitting ? true : undefined}
                    className="w-1/2 mx-auto md:w-full rounded-full py-2 px-8 flex items-center justify-center text-lg font-heading font-bold text-ec-sky bg-white hover:bg-ec-sky hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition ease-in-out border-2 border-ec-sky hover:border-white"
                  >
                    Subscribe
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default HeroNewsletter
