import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const ContactSection = () => {
  return (
    <div className="bg-white pb-12" id="services">
      <div className="max-w-screen-2xl px-4 py-12 mx-auto ">
        <h2 className="px-4 font-heading text-3xl mx-auto text-ec-earth text-center py-12">
          Visit Us In Store
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
          <div className="flex flex-col ">
            <p className=" text-center md:text-left font-body text-xl text-ec-sea leading-8 py-6">
              Our beautiful shop in Ormiston, Queensland continues to offer
              sanctuary for our customers where you can get hands on with the
              products and gain knowledge and guidance from our wonderful staff.
            </p>
            <p className="text-center md:text-left font-body text-xl text-ec-sea leading-8 py-6">
              Additional services provided in store are:
            </p>
            <ul className="text-center md:text-left font-body text-xl text-ec-sea leading-8 pl-4">
              <li className="flex flex-row items-center gap-4 justify-center md:justify-start pb-4">
                <svg
                  className="h-6 w-6"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="13"
                    cy="13"
                    r="12"
                    stroke="#0D3552"
                    strokeWidth="2"
                  />
                </svg>
                Healings
              </li>
              <li className="flex flex-row items-center gap-4 justify-center md:justify-start pb-4">
                <svg
                  className="h-6 w-6"
                  width="32"
                  height="27"
                  viewBox="0 0 32 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.9282 14L16 2L9.07182 14M22.9282 14L29.8564 26H2.14362L9.07182 14M22.9282 14H9.07182"
                    stroke="#0D3552"
                    strokeWidth="2"
                  />
                </svg>
                Readings
              </li>
            </ul>
            <Link
              to="/contact"
              className="mx-auto md:mx-0 font-heading text-xl text-white bg-ec-earth border-2 border-ec-earth w-72 rounded-full py-2 mt-8  px-8 transition ease-in-out hover:text-ec-earth hover:bg-transparent "
            >
              Enquire About Our Services
            </Link>
          </div>
          <StaticImage
            className="rounded-tr-[100px] rounded-bl-[100px]"
            src="https://images.unsplash.com/photo-1630908318796-5998f64b07cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1288&q=80"
            alt="crystals and holistic treatments"
          />
        </div>
      </div>
    </div>
  )
}

export default ContactSection
