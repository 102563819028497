import React from "react"

import crystals from "../assets/categories/CrystalIcon.png"
import crystalsHover from "../assets/categories/CrystalHover.png"
import jewellery from "../assets/categories/JewelleryIcon.png"
import jewelleryHover from "../assets/categories/JewelleryHover.png"
import Aromatherapy from "../assets/categories/AromaIcon.png"
import AromatherapyHover from "../assets/categories/AromaHover.png"
import Books from "../assets/categories/BookIcon.png"
import BooksHover from "../assets/categories/BookHover.png"
import Tarot from "../assets/categories/TarotIcon.png"
import TarotHover from "../assets/categories/TarotHover.png"
import { Link } from "gatsby"

const categories = [
  {
    name: "Crystals",
    icon: crystals,
    iconHover: crystalsHover,
    link: "/catalogue?p=Crystals",
  },
  {
    name: "Jewellery",
    icon: jewellery,
    iconHover: jewelleryHover,
    link: "/catalogue?p=Jewellery",
  },
  {
    name: "Aromatherapy",
    icon: Aromatherapy,
    iconHover: AromatherapyHover,
    link: "/catalogue?p=Aromatherapy",
  },
  {
    name: "Books",
    icon: Books,
    iconHover: BooksHover,
    link: "/catalogue?p=Books",
  },
  {
    name: "Tarot & Oracle",
    icon: Tarot,
    iconHover: TarotHover,
    link: "/catalogue?p=Tarot%20%26%20Oracle%20Cards",
  },
]

const Categories = () => {
  return (
    <div className="bg-ec-air py-12">
      <h2 className="px-4 font-heading text-3xl mx-auto text-ec-earth text-center">Shop By Category</h2>
      <div className="grid grid-cols-3 sm:grid-cols-5 max-w-screen-2xl mx-auto items-center pt-12">
        {categories.map((category) => {
          return (
            <Link to= {category.link} className="relative group col-span-1 text-center flex flex-col items-center justify-center pb-12" key={category.name}>
              <div className="h-36 md:h-44 flex items-center justify-center">
              <img src={category.icon} alt="" className="absolute transition ease-in-out h-24 sm:h-40 sm:w-40 duration-500" />
              <img src={category.iconHover} alt="" className="absolute opacity-0 group-hover:opacity-100 transition ease-in-out h-24 sm:h-40 sm:w-40 duration-500"/>
              </div>
              <h2 className="font-heading text-xl text-ec-sea">{category.name}</h2>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Categories
